import { forwardRef, useImperativeHandle, useState } from 'react';

import AutoCompleteDropdown from './AutoCompleteDropdown';
import { useLanguage } from '../contexts/LanguageContext';
import { useThesaurusContext } from '../contexts/ThesaurusContext';

import '../stylesheets/custom_dropdown.css';

const ChampDateParution = forwardRef(({ id, label, value, onChange, isPeriodique = false, source_id = '' }, ref) => {
	const format = require('../util/champsFormat');
	const { language } = useLanguage();
	const { thesaurus } = useThesaurusContext();

	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = format.validateDateParution(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleFocus = (e) => {
		setError(false);
	}

	let filteredOptions = [];
	if (isPeriodique && source_id) {
		filteredOptions = thesaurus.date_parution.filter(option => option.source_id === source_id);
	}

	let processedOptions = [];
	if (isPeriodique && filteredOptions.length > 0) {
		processedOptions = filteredOptions.map(option => ({
			...option,
			nom: format.processDateParution(option.id, language)
		}));
	}

	if (processedOptions.length === 0) {
		return (<div className="form-group">
			<label htmlFor={id}>{label}</label>
			<input
				type="text"
				id={id}
				value={value}
				onChange={onChange}
				className={error ? "error" : ""}
				disabled
			/>
		</div>);
	}

    return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <AutoCompleteDropdown
			id={id}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			options={processedOptions}
			maxLength="50"
			className={error ? "error" : ""}
		/>
    </div>);
});

export default ChampDateParution;