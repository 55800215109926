import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SearchBarModal from '../components/SearchBarModal';
import { useLanguage } from '../contexts/LanguageContext';
import { useThesaurusContext } from '../contexts/ThesaurusContext';

function SearchBar() {
	const { t } = useTranslation([]);
	const location = useLocation();
	const navigate = useNavigate();
	const { language } = useLanguage();
	const { thesaurus } = useThesaurusContext();
	const format = require('../util/champsFormat');

	const [searchPath, setSearchPath] = useState(language === 'fr' ? '/fr/search' : '/search');
	const searchBarRef = useRef('');
	const [error, setError] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);	

    useLayoutEffect(() => {
		// Reset search bar content on page change
        if (searchBarRef.current) {
            searchBarRef.current.value = '';
        }

		// Disable input if the path contains '/search'
		if (location.pathname.includes('/search')) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
    }, [location.pathname, navigate]);

	// Update content based on language
	useEffect(() => {
		setSearchPath(language === 'fr' ? '/fr/search' : '/search');
	}, [language]);

	const validate = () => {
		if (!searchBarRef.current) {
			return false;
		}
		let error = !format.validateTextFormat(searchBarRef.current.value);
		setError(error);
		setShowModal(error);
		return error;
	}

	const handleChange = () => {
		setError(false);
	}

	const handleBlur = () => {
		validate();
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!searchBarRef.current) {
			return;
		}

		const searchValue = searchBarRef.current.value.trim();
		const searchValueLower = searchValue.toLowerCase();

		// revalidate field format
		let error = validate();
		if (error) {
			return; // Prevent form submission
		}

		/* Search matching values in the thesaurus */
		const findThesaurusItem = (champ, options, bilingue = false) => {
			let foundItem = bilingue
            	? options.find(item => item.nom_fr.toLowerCase() === searchValueLower || item.nom_en.toLowerCase() === searchValueLower)
            	: options.find(item => item.nom.toLowerCase() === searchValueLower);
			if (foundItem) {
				// redirect to search page
				navigate(`${searchPath}?${champ}=${foundItem.id}`);
				// Indicate that a match was found and redirection has occurred
				return true;
			}
			return false;
		}

		if (searchValue) {
			let queryString;

			// codeImage
			if (searchValue.match(/VDI/)) {
				queryString = `code_image=${searchValue}`;
				navigate(`${searchPath}?${queryString}`);
				return;
			}

			// decennie
			if (format.validateDecennie(searchValue)) {
				queryString = `decennie=${searchValue}`;
				navigate(`${searchPath}?${queryString}`);
				return;
			}

			// annee
			else if (format.validateAnnee(searchValue)) {
				queryString = `annee=${searchValue}`;
				navigate(`${searchPath}?${queryString}`);
				return;
			}

			// Cas particulier sousCategorie
			let foundItem = thesaurus.sousCategories.find(item => item.nom_fr.toLowerCase() === searchValueLower || item.nom_en.toLowerCase() === searchValueLower);
			if (foundItem) {
				// redirect to search page
				navigate(`${searchPath}?categorie=${foundItem.parent}&sous_categorie=${foundItem.id}`);
				return;
			}

			// ID based fields
			if (
				findThesaurusItem("source", thesaurus.sources) ||
				findThesaurusItem("categorie", thesaurus.categories, true) ||
				findThesaurusItem("mot_cle", thesaurus.motsCles, true) ||
				findThesaurusItem("style", thesaurus.styles, true) ||
				findThesaurusItem("couturier", thesaurus.couturiers) ||
				findThesaurusItem("designer", thesaurus.designers) ||
				findThesaurusItem("coloris", thesaurus.coloris, true)
			) {
				return; // Stop further execution after first match and redirect
			}

			// legende
			queryString = `legende=${searchValue}`;
			navigate(`${searchPath}?${queryString}`);
		}
	}

	return (<>
		<form className="search">
			<input
				ref={searchBarRef}
				type="text"
				id="search-bar"
				placeholder={ t('header.search_bar', { ns: 'common' }) }
				onChange={handleChange}
				onBlur={handleBlur}
				maxLength="50"
				required
				disabled={isDisabled}  // Disable input based on the current path
				className={error ? "search-bar-error" : ""}
			/>
			<input
				type="submit"
				id="submit-icon"
				onClick={handleSubmit} />
		</form>
		{showModal && (
			<SearchBarModal onClose={() => setShowModal(false)} />
		)}
	</>);
}

export default SearchBar;