import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MetaTags from './MetaTags';
import api from '../api'
import SourceDateParution from './SourceDateParution.jsx';
import SourceAnnee from './SourceAnnee.jsx';
import PlancheContact from './PlancheContact';

import { useAuthContext } from '../hooks/useAuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import useLocalizedPath from '../hooks/useLocalizedPath';

import '../stylesheets/notice.css';
import '../stylesheets/source.css';

const type_publication_en = {
	"Autre": "Other",
	"Catalogue": "Catalogue",
	"Ouvrage": "Book",
	"Périodique": "Magazine",
	"Recueil de documents": "Compilation of documents"
}

function SourcePage() {
	const { t } = useTranslation(['source']);
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const { language } = useLanguage();
	const redirect_link = useLocalizedPath("/login");

	// protect page from visitors
    useLayoutEffect(() => {
        if (!user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	const { source_id } = useParams();
	const [source, setSource] = useState(null);
	const [notFound, setNotFound] = useState(false);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [serverError, setServerError] = useState(false);

	const numberOfFields = 2; // Number of fields in your form

	// fields refs to trigger validation onSubmit
	const fieldRefs = useRef(
        Array.from({ length: numberOfFields }, () => React.createRef())
    );

	// fields states
	const [annee, setAnnee] = useState('');
	const [dateParution, setDateParution] = useState('');

	// Fetch the source fiche
	useEffect(() => {
		const fetchSource = async () => {
			try {
				const response = await api.get(`/medias/source/${source_id}`);
				if (response.status === 200) {
					setSource(response.data.source);
					setImages(response.data.source.images.map(row => row.code_image));
					setLoading(false);
				}
			} catch (error) {
				setSource(null); // Clear source on error
				if (error.response) {
					if (error.response.status === 404) {
						setNotFound(true);
					}
					else {
						console.log("Error while retrieving source fiche");
						if (error.response && error.response.data && error.response.data.message) {
							console.error(error.response.data.message);
						}
					}
				}
				setLoading(false);
			}
		};

		setLoading(true);
		if (source_id) {
			fetchSource();
		}

		// eslint-disable-next-line
	},[source_id]);

	// Update images based on filters
	useEffect(() => {
		if (!source || !source.images) {
			return;
		}
		let filtered = source.images;
		if (annee) {
			filtered = filtered.filter(image => 
				image.annee === annee
			);
		}
		if (dateParution) {
			filtered = filtered.filter(image =>
				image.date_parution === dateParution	
			);
		}
		const filteredImages = filtered.map(row => row.code_image);
		setImages(filteredImages);
	},[source, annee, dateParution]);

	// Make Suspense display the Loader if user isn't loaded yet
	if (!user) {
        return null;
    }

	if (notFound) {
		return (<>
			<MetaTags />
			
			<h1>{ t('title', { ns: 'source' }) }</h1>
			<p>{ t('not_found', { ns: 'source' }) }</p>
		</>);
	}

	if (!source) {
		return (<>
			<MetaTags />

			<h1>{ t('title', { ns: 'source' }) }</h1>
			<p>{ t('loading', { ns: 'common' }) }</p>
		</>);
	}
	
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'source' }) }</h1>
		
		<div>
			<h2>{ t('fiche', { ns: 'source' }) }</h2>

			<div id="notice-container">
				<div className="champ">
					<div className="champ-nom">
						<p>{ t('champs.nom_source', { ns: 'common' }) }</p>
					</div>
					<div className="champ-value">
						<p>{ source.fiche.nom }</p>
					</div>
				</div>

				<div className="champ">
					<div className="champ-nom">
						<p>{ t('champs.type_publication', { ns: 'common' }) }</p>
					</div>
					<div className="champ-value">
						<p>{ language === "fr" ?
						source.fiche.type_publication :
						type_publication_en[source.fiche.type_publication] }</p>
					</div>
				</div>

				<div className="champ">
					<div className="champ-nom">
						<p>{ t('champs.date_publication', { ns: 'common' }) }</p>
					</div>
					<div className="champ-value">
						<p>{ source.fiche.date_publication ?
						source.fiche.date_publication :
						"–" }</p>
					</div>
				</div>

				<div className="champ">
					<div className="champ-nom">
						<p>{ t('champs.informations', { ns: 'common' }) }</p>
					</div>
					<div className="champ-value">
						<p>{ source.fiche.informations ?
						source.fiche.informations :
						"–" }</p>
					</div>
				</div>

				{source.fiche.collection &&
				<div className="champ">
					<div className="champ-nom">
						<p>Collection</p>
					</div>
					<div className="champ-value">
						<p>{ source.fiche.collection }</p>
					</div>
				</div>}

			</div>
		</div>

		<div>
			<h2>Images</h2>

			{((source.annees && source.annees.length > 0) ||
			(source.date_parution && source.date_parution.length > 0)) &&
			<form id="source-filters" noValidate>
				{source.annees && source.annees.length > 0 &&
				<SourceAnnee
					id="annee"
					value={annee}
					onChange={setAnnee}
					options={source.annees}
					ref={fieldRefs.current[0]}
				/>}

				{source.date_parution && source.date_parution.length > 0 &&
				<SourceDateParution
					id="dateParution"
					label={t('champs.date_parution', { ns: 'common' })}
					value={dateParution}
					onChange={setDateParution}
					options={source.date_parution}
					annee={annee}
					ref={fieldRefs.current[1]}
				/>}
			</form>}

			<div id="source-results">
				<PlancheContact results={images} loading={loading} no_results={t('no_results', { ns: 'source' })} />
				{serverError && <p className="server-error">{t('server_error', { ns: 'source' })}</p>}
			</div>
		</div>

	</>);
}

SourcePage.namespaces = ['source'];

export default SourcePage;