import React from 'react';
import { useTranslation } from 'react-i18next';

import AlertModal from '../components/AlertModal';

const RateLimitModal = ({ retryAfter, onClose }) => {
    const { t } = useTranslation();

    // Convert seconds to minutes and seconds
    const minutes = Math.floor(retryAfter / 60);
    const seconds = retryAfter % 60;
	const time = (minutes > 0 && `${minutes} min `) + `${seconds} s`;

    return (
		<AlertModal
			message={ t('modal.rate_limit_message', { retryAfter: time, ns: 'common' }) }
			close={ t('modal.close', { ns: 'common' }) }
			onClose={onClose}
		/>
    );
};

export default RateLimitModal;

