import { useTranslation } from 'react-i18next';
import ScrollToTop from 'react-scroll-to-top';

import LocalizedLink from './LocalizedLink';

import logoInstagram from '../assets/logo-Instagram.png';
import logoLinkedIn from '../assets/logo-LinkedIn.png';

function Footer() {
	const { t } = useTranslation([]);

	return (<>
		<footer className="page-footer">
			<div className="container">
				<nav className="bottomnav">
					<LocalizedLink to="/legal_notices" rel="nofollow">{ t('footer.link_legal_notices', { ns: 'common' }) }</LocalizedLink>
					<LocalizedLink to="/terms_of_service" rel="nofollow">{ t('footer.link_tos', { ns: 'common' }) }</LocalizedLink>
				</nav>
				<div id="copyright">
					© 2024 images GuenoMiller. { t('footer.copyright', { ns: 'common' }) }
				</div>
				<div id="reseaux">
					<div>
						<a href="https://www.instagram.com/imagesguenomiller">@imagesguenomiller</a>
						<a href="https://www.instagram.com/imagesguenomiller"><img src={logoInstagram} alt="Logo Instagram" /></a>
					</div>
					<div>
						<a href="https://www.linkedin.com/in/guenolee-milleret/">@guenolee-milleret</a>
						<a href="https://www.linkedin.com/in/guenolee-milleret/"><img src={logoLinkedIn} alt="Logo LinkedIn" /></a>
					</div>
				</div>
			</div>
			<ScrollToTop id="scroll-button" smooth={true} color="white" />
		</footer>
	</>);
}

export default Footer;