import { useState, useEffect } from 'react';
import '../stylesheets/custom_dropdown.css';

const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const AutoCompleteDropdown = ({ id, value, onChange, onFocus, options = [], maxLength = 50, className = "", setIsPeriodique = null, setDateParution = null }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [displayValue, setDisplayValue] = useState('');

	const normalizedOptions = options.map(option => ({
        ...option,
        normalizedNom: removeAccents(option.nom.toLowerCase())
    }));

    useEffect(() => {
        const selectedOption = options.find(option => option.id === value);
        if (selectedOption) {
            setDisplayValue(selectedOption.nom);
        } else {
            setDisplayValue(''); 
        }
    }, [value, options]);

    const handleSelect = (option) => {
        setDisplayValue(option.nom); 
        onChange(option.id);
        setIsOpen(false);

		// for champ "source"
		if (setIsPeriodique && option.type_publication === "Périodique") {
			setIsPeriodique(true);
		}
    };

    const handleInputChange = (event) => {
		// for champ "source"
		if (setIsPeriodique) {
			setDateParution('');
			setIsPeriodique(false);
		}
		// -------------------

        const userInput = removeAccents(event.target.value.toLowerCase());
        setDisplayValue(event.target.value);
        const filtered = normalizedOptions.filter(option => 
            option.normalizedNom.includes(userInput)
        );
        setFilteredOptions(filtered);
    };

    const handleBlur = () => {
        const selectedOption = options.find(option => option.nom === displayValue);
        if (!selectedOption) {
            setDisplayValue(''); // Reset the input display value
            onChange(''); // Reset the value passed to the parent component
        }
        setIsOpen(false); // Close the dropdown
    };

	const handleFocus = (e) => {
		onFocus(e);

		// Show all options when the input is focused if the user hasn't typed anything
        const filtered = displayValue
            ? normalizedOptions.filter(option =>
                option.normalizedNom.includes(removeAccents(displayValue.toLowerCase()))
              )
            : normalizedOptions;

        setFilteredOptions(filtered);
        setIsOpen(true);
	}

    return (
        <div className="custom-dropdown">
            <input
                id={id}
                value={displayValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
				onFocus={handleFocus}
                maxLength={maxLength}
				className={className}
            />
            {isOpen && filteredOptions.length > 0 && (
                <ul className="custom-dropdown-list">
                    {filteredOptions.map((option) => (
                        <li
                            key={option.id}
                            onMouseDown={() => handleSelect(option)}
                            className="custom-dropdown-item"
                        >
                            {option.nom}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutoCompleteDropdown;