import logoVDI from '../assets/logo-VDI-thumbnail.png';

function Loader() {
	return (
		<div className="loader">
			<img src={logoVDI} alt="Logo" />
			<div>En chargement…</div>
			<div>Loading…</div>
		</div>
	);
}

export default Loader;