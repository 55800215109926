import { useTranslation } from 'react-i18next';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';

import api from '../api'
import LocalizedLink from './LocalizedLink';

import '../stylesheets/media.css';

function FavouriteBar({ isFavourite, setIsFavourite, code_image, user_id }) {
	const { t } = useTranslation(['media']);

	const handleAddFavourite = async () => {
		try {
			const response = await api.post(`/users/${user_id}/favourites/${code_image}`);
			if (response.status === 200) {
				setIsFavourite(true);
			}
		} catch (error) {
			console.log("Error while adding image to user's favourites");
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
		}
	}

	const handleRemoveFavourite = async () => {
		try {
			const response = await api.delete(`/users/${user_id}/favourites/${code_image}`);
			if (response.status === 200) {
				setIsFavourite(false);
			}
		} catch (error) {
			console.log("Error while adding image to user's favourites");
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
		}
	}

	return (<>{isFavourite ?
		<div id="add-favourite">
			<p>
				{ t('remove_favourite', { ns: 'media' }) }
				<LocalizedLink  to="/favourites">{ t('header.link_favourites', { ns: 'common' }) }</LocalizedLink>
			</p>
			<button onClick={handleRemoveFavourite} id="remove-fav"><IoHeart /></button>
		</div> :
		<div id="add-favourite">
			<p>
				{ t('add_favourite', { ns: 'media' }) }
				<LocalizedLink  to="/favourites">{ t('header.link_favourites', { ns: 'common' }) }</LocalizedLink>
			</p>
			<button onClick={handleAddFavourite}><IoHeartOutline /></button>
		</div>
	}</>);
}

export default FavouriteBar;