import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SelectDropdown from './SelectDropdown';
import { useThesaurusContext } from '../contexts/ThesaurusContext';

import '../stylesheets/custom_dropdown.css';

const ChampSousCategorie = forwardRef(({ id, value, onChange, categorie, setCategorie }, ref) => {
	const { t } = useTranslation(['search']);
	const format = require('../util/champsFormat');
	const { thesaurus } = useThesaurusContext();

	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = format.validateIdFormat(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	/* Link categorie and sousCategorie fields */
	// Auto update categorie based on sousCategorie
	useEffect(() => {
        if (value) {
			// Ensure Asynchronous Data is Loaded
			if (!thesaurus.sousCategories) return;
			
            // Find the sousCategorie where option.id === value
            const selectedOption = thesaurus.sousCategories.find(option => option.id === value);
            if (selectedOption) {
                // Update the categorie based on the parent of the selected sousCategorie
                setCategorie(selectedOption.parent);
            }
        }
    }, [value, thesaurus.sousCategories, setCategorie]);

	// Filter sousCategories options based on categorie
	const [filteredOptions, setFilteredOptions] = useState(thesaurus.sousCategories);
	useEffect(() => {
        if (categorie) {
            // Filter the sousCategories to only include those where option.parent === categorie
            const filtered = thesaurus.sousCategories.filter(option => option.parent === categorie);
            setFilteredOptions(filtered);
        } else {
            // If no categorie is selected, reset to all options
            setFilteredOptions(thesaurus.sousCategories);
        }
    }, [categorie, thesaurus.sousCategories]);

	const handleFocus = (e) => {
		setError(false);
	}

    return (<div className="form-group">
		<label htmlFor={id}>{t('champs.sous_categorie', { ns: 'common' })}</label>
        <SelectDropdown
			id={id}
			options={filteredOptions}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			placeholder={t('champs.select', { ns: 'common' })}
			className={error ? "error" : ""}
		/>
    </div>);
});

export default ChampSousCategorie;