import '../stylesheets/modal.css';

const AlertModal = ({ message, close, onClose }) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <p>{message}</p>
				<div className="modal-buttons">
					<button onClick={onClose}>{close}</button>
				</div>
            </div>
        </div>
    );
};

export default AlertModal;