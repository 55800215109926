import { useTranslation } from 'react-i18next';

import MetaTags from './MetaTags';
import LocalizedLink from './LocalizedLink';
import HomeGalleryVisitor from './HomeGalleryVisitor';
import HomeGalleryConnected from './HomeGalleryConnected';
import { useAuthContext } from '../hooks/useAuthContext';

import '../stylesheets/home.css';

function HomePage() {
	const { t } = useTranslation(['home']);
	const { user } = useAuthContext();

	const visitor_content = (<>
		<div>
			<p>{ t('text_visit', { ns: 'home' }) }</p>
		</div>
		<div id="inscription">
			<LocalizedLink to="/register">{ t('link_register', { ns: 'common' }) }</LocalizedLink>
		</div>
		<div id="galeries-visitor">
			<h2 className="color-bar">{ t('categories.mode', { ns: 'db' }) }</h2>
			<HomeGalleryVisitor category="mode" quantity={16} />
			<h2 className="color-bar">{ t('categories.architecture', { ns: 'db' }) }</h2>
			<HomeGalleryVisitor category="architecture" quantity={12} />
			<h2 className="color-bar">{ t('categories.decor', { ns: 'db' }) }</h2>
			<HomeGalleryVisitor category="decor" quantity={12} />
			<h2 className="color-bar">{ t('categories.gastronomie', { ns: 'db' }) }</h2>
			<HomeGalleryVisitor category="gastronomie" quantity={8} />
		</div>
	</>);

	const categorie_mode = ['toilettes-et-tenues', 'modes-masculines', 'modes-enfantines', 'costumes-traditionnels', 'costumes-types-et-uniformes', 'accessoires', 'coiffures-et-coiffes', 'lingerie'];

	const categorie_architecture = ['architecture-privee', 'architecture-commerciale', 'elements-architecturaux'];

	const categorie_decor = ['interieurs-d-appartements', 'interieurs-de-magasins', 'mobilier', 'interieurs-de-theatres', 'motifs-ornementaux', 'planches-botaniques'];

	const categorie_gastronomie = ['arts-culinaires', 'arts-de-la-table'];

	const connected_content = (<>
		<div>
			<p>{ t('text_connected', { ns: 'home' }) }</p>
		</div>
		<div id="galeries-connected">
			<h2 className="color-bar">{ t('categories.mode', { ns: 'db' }) }</h2>
			<HomeGalleryConnected categorie_id={1} start={1} subcategories={categorie_mode} />
			<h2 className="color-bar">{ t('categories.architecture', { ns: 'db' }) }</h2>
			<HomeGalleryConnected categorie_id={2} start={9} subcategories={categorie_architecture} />
			<h2 className="color-bar">{ t('categories.decor', { ns: 'db' }) }</h2>
			<HomeGalleryConnected categorie_id={3} start={12} subcategories={categorie_decor} />
			<h2 className="color-bar">{ t('categories.gastronomie', { ns: 'db' }) }</h2>
			<HomeGalleryConnected categorie_id={4} start={18} subcategories={categorie_gastronomie} />
		</div>
	</>);
	
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'home' }) }</h1>
		{user ? connected_content : visitor_content}
	</>);
}

HomePage.namespaces = ['home'];

export default HomePage;