import React, { createContext, useContext, useEffect, useState } from 'react';

import api from '../api';
import { useAuthContext } from '../hooks/useAuthContext';

/* Build a context containing thesaurus of each Notice field */

const ThesaurusContext = createContext(null);

export const ThesaurusContextProvider = ({ children }) => {
	const { user } = useAuthContext();
    const [thesaurus, setThesaurus] = useState({
        sources: [],
		date_parution: [],
        categories: [
			{
				id: 1,
				nom_fr: "Modes & Costumes",
				nom_en: "Fashion & Costumes"
			},
			{
				id: 2,
				nom_fr: "Architecture",
				nom_en: "Architecture"
			},
			{
				id: 3,
				nom_fr: "Décoration intérieure",
				nom_en: "Interior design"
			},
			{
				id: 4,
				nom_fr: "Gastronomie",
				nom_en: "Gastronomy"
			},
		],
        sousCategories: [],
        motsCles: [],
        styles: [],
		decennies: [],
        annees: [],
        couturiers: [],
        designers: [],
        coloris: [],
    });
	const [maxTables, setMaxTables] = useState({
		"Categories": 4,
		"Coloris": 259,
		"Couturiers": 22,
		"Designers_illustrateurs": 93,
		"Mots_cles": 1673,
		"Sources": 165,
		"Sous_categories": 19,
		"Styles": 59
	});
	// default values = count(*) de chaque Table au 17/08/2024

    // Error handling function
    const handleFetchError = (error, retryFunction) => {
		if (error.response) {
			if (error.response.status === 499 && error.response.data.retry_after) {
				const retryAfter = error.response.data.retry_after * 1000; // Convert seconds to milliseconds
				console.error(`Retrying after ${retryAfter / 1000} seconds due to 499 status.`);
				setTimeout(retryFunction, retryAfter);
			} else if (error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
		}
	};

    // Fetch thesaurus from DB
    const fetchSources = async () => {
        if (thesaurus.sources.length === 0) {
            try {
                const response = await api.get('/medias/sources');
                setThesaurus((prev) => ({ ...prev, sources: response.data.rows }));
            } catch (error) {
                handleFetchError(error, fetchSources);
            }
        }
    };

	const fetchDatesParution = async () => {
        if (thesaurus.date_parution.length === 0) {
            try {
                const response = await api.get('/medias/date_parution');
                setThesaurus((prev) => ({ ...prev, date_parution: response.data.rows }));
            } catch (error) {
                handleFetchError(error, fetchDatesParution);
            }
        }
    };

	const fetchSousCategories = async () => {
        if (thesaurus.sousCategories.length === 0) {
            try {
                const response = await api.get('/medias/sous_categories');
                setThesaurus((prev) => ({ ...prev, sousCategories: response.data.rows }));
            } catch (error) {
                handleFetchError(error, fetchSousCategories);
            }
        }
    };

    const fetchMotsCles = async () => {
        if (thesaurus.motsCles.length === 0) {
            try {
                const response = await api.get('/medias/mots_cles');
                setThesaurus((prev) => ({ ...prev, motsCles: response.data.rows }));
            } catch (error) {
                handleFetchError(error, fetchMotsCles);
            }
        }
    };

    const fetchStyles = async () => {
        if (thesaurus.styles.length === 0) {
            try {
                const response = await api.get('/medias/styles');
                setThesaurus((prev) => ({ ...prev, styles: response.data.rows }));
            } catch (error) {
                handleFetchError(error, fetchStyles);
            }
        }
    };

	const fetchDecennies = async () => {
        if (thesaurus.decennies.length === 0) {
            try {
                const response = await api.get('/medias/decennies');
                setThesaurus((prev) => ({ ...prev, decennies: response.data.values }));
            } catch (error) {
                handleFetchError(error, fetchDecennies);
            }
        }
    };

	const fetchAnnees = async () => {
        if (thesaurus.annees.length === 0) {
            try {
                const response = await api.get('/medias/annees');
                setThesaurus((prev) => ({ ...prev, annees: response.data.values }));
            } catch (error) {
                handleFetchError(error, fetchAnnees);
            }
        }
    };

    const fetchCouturiers = async () => {
        if (thesaurus.couturiers.length === 0) {
            try {
                const response = await api.get('/medias/couturiers');
                setThesaurus((prev) => ({ ...prev, couturiers: response.data.rows }));
            } catch (error) {
                handleFetchError(error, fetchCouturiers);
            }
        }
    };

    const fetchDesigners = async () => {
        if (thesaurus.designers.length === 0) {
            try {
                const response = await api.get('/medias/designers_illustrateurs');
                setThesaurus((prev) => ({ ...prev, designers: response.data.rows }));
            } catch (error) {
                handleFetchError(error, fetchDesigners);
            }
        }
    };

    const fetchColoris = async () => {
        if (thesaurus.coloris.length === 0) {
            try {
                const response = await api.get('/medias/coloris');
                setThesaurus((prev) => ({ ...prev, coloris: response.data.rows }));
            } catch (error) {
                handleFetchError(error, fetchColoris);
            }
        }
    };

	// Fetch count(*) de chaque table
	const fetchMaxTables = async () => {
		try {
			const response = await api.get('/medias/count_tables');
			setMaxTables(response.data.maxTables);
		} catch (error) {
			handleFetchError(error, fetchMaxTables);
		}
    };

    // Fetch the thesaurus once
    useEffect(() => {
		if (user) {
			fetchMaxTables();

			fetchSources();
			fetchDatesParution();
			fetchSousCategories();
			fetchMotsCles();
			fetchStyles();
			fetchDecennies();
			fetchAnnees();
			fetchCouturiers();
			fetchDesigners();
			fetchColoris();
		}
        // eslint-disable-next-line
    }, [user]);

    return (
        <ThesaurusContext.Provider value={{thesaurus, maxTables}}>
            {children}
        </ThesaurusContext.Provider>
    );
};

export const useThesaurusContext = () => useContext(ThesaurusContext);