const monthNames = {
    en: {
        '01': 'January', '02': 'February', '03': 'March', '04': 'April',
        '05': 'May', '06': 'June', '07': 'July', '08': 'August',
        '09': 'September', '10': 'October', '11': 'November', '12': 'December'
    },
    fr: {
        '01': 'Janvier', '02': 'Février', '03': 'Mars', '04': 'Avril',
        '05': 'Mai', '06': 'Juin', '07': 'Juillet', '08': 'Août',
        '09': 'Septembre', '10': 'Octobre', '11': 'Novembre', '12': 'Décembre'
    }
};

const processDateParution = (id, language) => {
	const monthNamesInLanguage = monthNames[language];

	const transformDate = (dateStr) => {
		const [year, month, day] = dateStr.split('-');
		if (month === '00') {
			return year; // YYYY-00-00
		}
		if (day === '00') {
			return `${monthNamesInLanguage[month]} ${year}`; // YYYY-MM-00
		}
		return `${parseInt(day)} ${monthNamesInLanguage[month]} ${year}`; // YYYY-MM-DD
	};

	const regex = /(\d{4}-\d{2}-\d{2})|(\d{4})(\s*\(ca\.\))?|(\d{4}-\d{4})|(\d{4})(?!-)/g;
	let match;
	const parts = [];

	while ((match = regex.exec(id)) !== null) {
		if (match[1]) { // YYYY-MM-DD or YYYY-MM-00 or YYYY-00-00
			parts.push(transformDate(match[1]));
		} else if (match[2]) { // YYYY with optional (ca.)
			parts.push(match[2] + (match[3] || '')); // Append (ca.) if it exists
		} else if (match[4]) { // YYYY-YYYY
			parts.push(match[4]);
		} else if (match[5]) { // YYYY (Standalone year without a pattern match)
			parts.push(match[5]);
		}
	}

	if (parts.length > 1) {
		return parts.join(language === 'fr' ? ' et ' : ' and ');
	}

	return parts.length > 0 ? parts[0] : ''; // Return the processed string or an empty string if no matches
};

const processDateParutionSource = (id, language) => {
    const monthNamesInLanguage = monthNames[language];

    const transformDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        if (month === '00') {
            return year; // YYYY-00-00
        }
        if (day === '00') {
            return `${monthNamesInLanguage[month]} ${year}`; // YYYY-MM-00
        }
        return `${parseInt(day)} ${monthNamesInLanguage[month]} ${year}`; // YYYY-MM-DD
    };

    const regex = /(\d{4}-\d{2}-\d{2})(.*)?|(\d{4})(\s*\(ca\.\))?(.*)?|(\d{4}-\d{4})(.*)?/g;
    let match;
    const parts = [];

    while ((match = regex.exec(id)) !== null) {
        if (match[1]) { // YYYY-MM-DD or YYYY-MM-00 or YYYY-00-00
            const datePart = transformDate(match[1]);
            const followingText = match[2] ? ` ${match[2].trim()}` : '';
            parts.push(datePart + followingText);
        } else if (match[3]) { // YYYY with optional (ca.) and any following text
            const datePart = match[3] + (match[4] || '');
            const followingText = match[5] ? ` ${match[5].trim()}` : '';
            parts.push(datePart + followingText);
        } else if (match[6]) { // YYYY-YYYY with any following text
            const datePart = match[6];
            const followingText = match[7] ? ` ${match[7].trim()}` : '';
            parts.push(datePart + followingText);
        }
    }

    if (parts.length > 1) {
        return parts.join(language === 'fr' ? ' et ' : ' and ');
    }

    return parts.length > 0 ? parts[0] : ''; // Return the processed string or an empty string if no matches
};

const validateAnnee = (value) => {
	if (value) {
		return value.match(/^1\d{3}(-1\d{3})?$/);
	}
	return true;
};

const validateDateParution = (value) => {
	if (value) {
		return value.match(/^[\da-zA-ZÀ-ÿ().\s-]*$/);
	}
	return true;
};

const validateDecennie = (value) => {
	// Check if the value is a 4-digit year ending with 0
	if (value) {
		value = String(value);
		return value.match(/^1\d{2}0$/);
	}
	return true;
};

const validateIdFormat = (value) => {
	if (value) {
		value = String(value);
		return value.match(/^\d*$/);
	}
	return true;
}

const validateTextFormat = (value) => {
	if (value) {
		return value.match(/^[\da-zA-ZÀ-ÿ'–&(),.:;°\s-]*$/);
	}
	return true;
};

module.exports = {
	processDateParution,
	processDateParutionSource,
	validateAnnee,
	validateDateParution,
	validateDecennie,
	validateIdFormat,
	validateTextFormat
}