import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAddCircleOutline, IoRemoveCircleOutline, IoCloseCircleOutline } from 'react-icons/io5';

import { useThesaurusContext } from '../contexts/ThesaurusContext';

import '../stylesheets/custom_decade_selector.css';

const DecadeSelector = ({ value, onChange, onBlur, onFocus, options = [], className, setAnnee }) => {
    const [isFocused, setIsFocused] = useState(false);
	const [selectedOption, setSelectedOption] = useState(-1);

	useEffect(() => {
        if (value === '') {
            setSelectedOption(-1);
        } else {
            const index = options.indexOf(value);
            setSelectedOption(index !== -1 ? index : -1);
        }
    }, [value, options]);

    const increment = () => {
        if (selectedOption === -1) {
            setSelectedOption(0);
            onChange(options[0]);
        } else if (selectedOption < options.length - 1) {
            const newIndex = selectedOption + 1;
            setSelectedOption(newIndex);
			setAnnee(''); // Reset annee if decennie changes
            onChange(options[newIndex]);
        }
    };

    const decrement = () => {
        if (selectedOption > 0) {
            const newIndex = selectedOption - 1;
            setSelectedOption(newIndex);
			setAnnee(''); // Reset annee if decennie changes
            onChange(options[newIndex]);
        }
        // No action is taken if selectedOption === 0, to prevent decrementing below the first option.
    };

	const remove = () => {
        setSelectedOption(-1);
        onChange('');
    };

	const handleFocus = (e) => {
        setIsFocused(true);
        if (onFocus) onFocus(e);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        if (onBlur) onBlur(e);
    };

	// decrement button is a "em dash" = 2 "-"

    return (
        <div className={`custom-decade-selector ${isFocused ? 'focused' : ''}`}>
            <button type="button" onClick={decrement} className="decrement-button"><IoRemoveCircleOutline /></button>
            <input
                type="text"
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
				className={className}
                readOnly
            />
            <button type="button" onClick={increment} className="increment-button"><IoAddCircleOutline /></button>
			<button type="button" onClick={remove} className="remove-button"><IoCloseCircleOutline /></button>
        </div>
    );
};

const ChampDecennie = forwardRef(({ id, label, value, onChange, setAnnee }, ref) => {
	const { t } = useTranslation(['search']);
	const format = require('../util/champsFormat');
	const { thesaurus } = useThesaurusContext();

	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = format.validateDecennie(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleBlur = (e) => {
		const { value } = e.target;
		let error = !format.validateDecennie(value);
		setError(error);
	}

	const handleFocus = (e) => {
		setError(false);
	}

	return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <DecadeSelector
			value={value}
			onChange={onChange}
			onBlur={handleBlur}
			onFocus={handleFocus}
			options={thesaurus.decennies}
			className={error ? "error" : ""}
			setAnnee={setAnnee}
		/>
		{error && <p className="error">{t('error.invalid_decade', { ns: 'search' })}</p>}
    </div>);
});

export default ChampDecennie;