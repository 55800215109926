import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ChampText = forwardRef(({ id, label, value, onChange, maxLength = "50", isConflicted = false, setIsConflicted = null }, ref) => {
	const { t } = useTranslation(['search']);
	const format = require('../util/champsFormat');

	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	// maxLength = taille max de value
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
		if (isConflicted) {
			setIsConflicted(false);
		}
	}

	const validate = () => {
		let validation = format.validateTextFormat(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleChange = (e) => {
		const { value } = e.target;
		onChange(value);
		if (setIsConflicted) {
			setIsConflicted(false);
		}
	}

	const handleBlur = (e) => {
		const { value } = e.target;
		onChange(value.trim());
		let error = !format.validateTextFormat(value);
		setError(error);
	}

	const handleFocus = (e) => {
		setError(false);
	}

	return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <input
			type="text"
			id={id}
			value={value}
			onChange={handleChange}
			onBlur={handleBlur}
			onFocus={handleFocus}
			maxLength={maxLength}
			className={(error || isConflicted) ? "error" : ""}
		/>
		{error && <p className="error">{t('error.field_format', { ns: 'search' })}</p>}
    </div>);
});

export default ChampText;