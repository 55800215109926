import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';

function NamespaceLoader({ element: Component }) {
    const { i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadNamespaces = async () => {
            if (Component.namespaces) {
                await i18n.loadNamespaces(Component.namespaces);
            }
            setIsLoading(false);
        };
        
        loadNamespaces();
    }, [Component, i18n]);

    if (isLoading) {
        return <Loader />;
    }

    return <Component />;
}

export default NamespaceLoader;
