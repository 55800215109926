import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext();

export const LanguageContextProvider = ({ children }) => {
	const { i18n } = useTranslation(); // Access i18n instance
	const [language, setLanguage] = useState(i18n.language || 'en'); // Initialize with current i18n language

	useEffect(() => {
    	setLanguage(i18n.language);
	}, [i18n.language]);

	// update i18n language
	const changeLanguage = (newLanguage) => {
    	i18n.changeLanguage(newLanguage); // Change i18n language directly
  	};

	return (
    	<LanguageContext.Provider value={{ language, changeLanguage }}>
    		{children}
		</LanguageContext.Provider>
	);
};

export const useLanguage = () => useContext(LanguageContext);