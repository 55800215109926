import { forwardRef, useImperativeHandle, useState } from 'react';

import AutoCompleteDropdownLanguage from './AutoCompleteDropdownLanguage';

import '../stylesheets/custom_dropdown.css';

const ChampIdLanguage = forwardRef(({ id, label, value, onChange, options = [], maxLength = "50" }, ref) => {
	const format = require('../util/champsFormat');

	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	// options = Array de JSON avec properties "id" et "nom"
	// maxLength = taille max de value
	const [error, setError] = useState(false);

	const validate = () => {
		let validation = format.validateIdFormat(value);
		setError(!validation);
		return validation;
	}

	const reset = () => {
		onChange('');
		setError(false);
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleFocus = (e) => {
		setError(false);
	}

    return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <AutoCompleteDropdownLanguage
			id={id}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			options={options}
			maxLength={maxLength}
			className={error ? "error" : ""}
		/>
    </div>);
});

export default ChampIdLanguage;