import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '../components/ConfirmModal';

const DeleteAccountModal = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation();

	return (
		<ConfirmModal
			message={ t('modal.delete_account_message', { ns: 'common' }) }
			confirm={ t('modal.confirm', { ns: 'common' }) }
			cancel={ t('modal.cancel', { ns: 'common' }) }
			onConfirm={onConfirm}
			onCancel={onCancel}
		/>
	);
};

export default DeleteAccountModal;
