import axios from 'axios';

const api = axios.create({
	baseURL: '/api',  // Use a relative URL
	withCredentials: true
});

api.defaults.withCredentials = true;

export const setupInterceptors = (dispatch, setShowRateLimitModal, setRetryAfter) => {
    api.interceptors.response.use(
        function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
        function (error) {
			if (error.response) {
				if (error.response.status === 401) {
					// Remove user from localStorage if token is invalid/expired
					localStorage.removeItem("user");

					// Update the auth context = dispatch logout action
					dispatch({
						type: 'LOGOUT'
					});
				}

				if (error.response.status === 403 && error.response.data.accessDenied) {
                    // Retrieve user language from localStorage
                    const userLanguage = localStorage.getItem('userLanguage') || 'en';
                    
                    // Determine the path prefix based on the language
                    const languagePrefix = userLanguage === 'fr' ? '/fr' : '';
                    
                    // Navigate to the forbidden page
                    window.location.assign(`${languagePrefix}/forbidden`);
                }

				// Display a modal when the user reaches the rate limit
				if (error.response.status === 499) {
                    const retryAfter = error.response.data.retry_after;
                    setRetryAfter(retryAfter); // Set retry after value in seconds
                    setShowRateLimitModal(true); // Show the modal
                }
			}
            return Promise.reject(error);
        }
    );
};

export default api;