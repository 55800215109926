import React from 'react';
import { useTranslation } from 'react-i18next';

import AlertModal from '../components/AlertModal';

const SearchBarModal = ({ onClose }) => {
    const { t } = useTranslation();

    return (
        <AlertModal
			message={ t('modal.search_bar_message', { ns: 'common' }) }
			close={ t('modal.close', { ns: 'common' }) }
			onClose={onClose}
		/>
    );
};

export default SearchBarModal;