import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../api';
import LocalizedLink from './LocalizedLink';

import '../stylesheets/planche_contact.css';

function ItemSearch(props) {
	const { t } = useTranslation([]);

	const [thumbnailUrl, setThumbnailUrl] = useState(null);
	const { code_image } = props;

	useEffect(() => {
		// Function to fetch the thumbnail from the server
		const fetchThumbnail = async () => {
			try {
				// Make the request to get the thumbnail image as a Blob
				const response = await api.get(`/medias/thumbnail/${code_image}`, {
					responseType: 'blob', // This tells axios to treat the response as a Blob
				});

				// Handle the response
				if (response.status === 200 || response.status === 304) {
					const url = URL.createObjectURL(response.data);
					setThumbnailUrl(url);
				}
			} catch (error) {
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				} else {
					console.error(`Error fetching thumbnail ${code_image}: `, error);
				}
			}
		};

		// Call the function to fetch the thumbnail
		fetchThumbnail();
	}, [code_image]);
	
	const mediaLink = `/media/${code_image}`;

	return (
		<div className="item-planche-contact">
			<LocalizedLink to={mediaLink} rel="nofollow">
				<div className="item-thumbnail">
					{thumbnailUrl ? (
						<img src={thumbnailUrl} alt="Thumbnail" />
					) : (
						<p>{ t('loading', { ns: 'common' }) }</p>
					)}
				</div>
				<p className="planche-contact-code-image">{code_image}</p>
			</LocalizedLink>
		</div>
	);
}

export default ItemSearch;