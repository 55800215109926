import { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import '../stylesheets/custom_dropdown.css';

const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const AutoCompleteDropdownLanguage = ({ id, value, onChange, onFocus, options = [], maxLength = 50, className = "" }) => {
	const { language } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [displayValue, setDisplayValue] = useState('');

	const normalizedOptions = options.map(option => ({
        ...option,
        normalizedNom: removeAccents(
            (language === 'fr' ? option.nom_fr : option.nom_en).toLowerCase()
        )
    }));

    useEffect(() => {
        const selectedOption = options.find(option => option.id === value);
        if (selectedOption) {
            const label = language === 'fr' ? selectedOption.nom_fr : selectedOption.nom_en;
            setDisplayValue(label);
        } else {
            setDisplayValue(''); 
        }
    }, [value, options, language]);

    const handleSelect = (option) => {
        const label = language === 'fr' ? option.nom_fr : option.nom_en;
        setDisplayValue(label); 
        onChange(option.id); 
        setIsOpen(false);
    };

    const handleInputChange = (event) => {
        const userInput = removeAccents(event.target.value.toLowerCase());
        setDisplayValue(event.target.value);
        const filtered = normalizedOptions.filter(option => 
            option.normalizedNom.includes(userInput)
        );
        setFilteredOptions(filtered);
        setIsOpen(true); 
    };

    const handleBlur = () => {
        const selectedOption = options.find(option => {
            const label = language === 'fr' ? option.nom_fr : option.nom_en;
            return label === displayValue;
        });

        if (!selectedOption) {
            setDisplayValue(''); // Reset the input display value
            onChange(''); // Reset the value passed to the parent component
        }

        setIsOpen(false); // Close the dropdown
    };

	const handleFocus = (e) => {
		onFocus(e);
	}

    return (
        <div className="custom-dropdown">
            <input
                id={id}
                value={displayValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
				onFocus={handleFocus}
                maxLength={maxLength}
				className={className}
            />
            {isOpen && filteredOptions.length > 0 && (
                <ul className="custom-dropdown-list">
                    {filteredOptions.map((option) => (
                        <li
                            key={option.id}
                            onMouseDown={() => handleSelect(option)}
                            className="custom-dropdown-item"
                        >
                            {language === 'fr' ? option.nom_fr : option.nom_en}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutoCompleteDropdownLanguage;