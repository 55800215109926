import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MetaTags from './MetaTags';
import Favourites from './Favourites';
import Paniers from './Paniers';
import { useAuthContext } from '../hooks/useAuthContext';
import useLocalizedPath from '../hooks/useLocalizedPath';

import '../stylesheets/favourites.css';

function FavouritesPage() {
	const { t } = useTranslation(['favourites']);
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const redirect_link = useLocalizedPath("/login");

	// protect page from visitors
    useLayoutEffect(() => {
        if (!user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	// Make Suspense display the Loader if user isn't loaded yet
	if (!user) {
        return null;
    }
	
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'favourites' }) }</h1>
		
		{user.role >= 2 ? <Paniers user={user} /> : <Favourites user={user} />}
	</>);
}

FavouritesPage.namespaces = ['favourites', 'planche_contact'];

export default FavouritesPage;