import React, { createContext, useContext } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Root from '../components/Root';
import TransitionRoute from '../components/TransitionRoute';

import { useGenerateRoutes } from '../hooks/useGenerateRoutes';

// Context to hold routes
const RouteContext = createContext(null);

// Provider component
export const RouteContextProvider = ({ children }) => {
	const { generateRoutes } = useGenerateRoutes();

	// Generate routes for both default language and French
	const defaultRoutes = generateRoutes('');
	const frenchRoutes = generateRoutes('/fr');

	// Combine the routes into one array
	const allRoutes = [
		...defaultRoutes,
		...frenchRoutes
	];

	// Create the router configuration
	const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            children: allRoutes.map(route => ({
                ...route,
                element: <TransitionRoute element={route.element} />,
            })),
        }
    ]);

	return (
    	<RouteContext.Provider value={router}>
      		{children}
    	</RouteContext.Provider>
	);
};

export const useRouter = () => useContext(RouteContext);