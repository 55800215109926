const sanitizeFileName = (name) => {
    return name
        .normalize("NFD")  // Normalize to decompose accented characters
        .replace(/[\u0300-\u036f]/g, "")  // Remove accents
        .replace(/[^a-zA-Z0-9\-_ ]/g, "")  // Remove all non-alphanumeric except hyphens, underscores, and spaces
        .trim();  // Trim spaces from the start and end
};

// Function to scroll to the top of the page smoothly
const scrollToTop = () => {
	// requestAnimationFrame ensures the function is called after DOM updates
	window.requestAnimationFrame(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
	  	});
	});
};

module.exports = {
	sanitizeFileName,
	scrollToTop
}