import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from '../api'
import { useLanguage } from '../contexts/LanguageContext';

import '../stylesheets/form.css';

function CreatePanier({ user, nbPaniers, setRefresh }) {
	const { t } = useTranslation(['favourites']);
	const { language } = useLanguage();

	const [nom, setNom] = useState('');
	const [error, setError] = useState('');
	const [serverError, setServerError] = useState('');
	const [nomConflicted, setNomConflicted] = useState(false);
	const [success, setSuccess] = useState(false);

	const validatePanierNom = (nom) => {
        if (!nom) {
            return t('error.panier_nom_empty', { ns: 'favourites' });
        } else if (!nom.match(/^[\d a-zA-ZÀ-ÿ'-]+$/)) {
            return t('error.panier_nom_format', { ns: 'favourites' });
        }
        return '';
    };

	const handleChange = (e) => {
		const { id, value } = e.target;

		if (id === 'nom') {
			setNom(value);
			setNomConflicted(false);	// Reset Conflicted state on change
		}
	};

	const handleBlur = (e) => {
		setNom(nom.trim())
		const error = validatePanierNom(nom.trim());
		setError(error);
	};

	const handleFocus = (e) => {
		// Reset field error on focus
		setError('');
	};

	const handleSubmit = async (event) => {
        event.preventDefault();
		setServerError('');
		setSuccess(false);

		// Revalidate all fields on submit to capture any changes
		setNom(nom.trim())
		const error = validatePanierNom(nom.trim());
		setError(error);

		// Prevent form submission if any error exists
		if (error) return;

		// Prevent API call if nbPaniers exceeds quota
		if (user.role !== 4 && nbPaniers >= 10) {
			setServerError( t('error.too_many_paniers', { ns: 'favourites' }) );
			return;
		}

		try {
            const response = await api.post(`/partners/${user.user_id}/paniers`, {
                panier_nom: nom
            });
            if (response.status === 200) {
				setServerError('');
				setSuccess(true);
				setNom('');
				setRefresh((prev) => !prev);
            }
        } catch (error) {
			if (error.response && error.response.status === 409) {
					setNomConflicted(true);
                	setServerError( t('error.panier_nom_used', { ns: 'favourites' }) );
			} else {
                setServerError( t('error.create_panier', { ns: 'favourites' }) );
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        }
    };

	return (<>
		<form className="create-panier" onSubmit={handleSubmit} noValidate>
			<div className="form-group">
				<label htmlFor="nom">{ t('champs.panier_nom', { ns: 'common' }) }</label>
				<input
					type="text"
					id="nom"
					value={nom}
					onChange={handleChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					required
					maxLength="50"
					className={(error || nomConflicted) ? 'error' : ''}
				/>
				{error && <p className="error">{error}</p>}
				{success && <p id="request-success">{t('create_success', { ns: 'favourites' })}</p>}
			</div>

			<div id="submit-wrapper">
				<button type="submit">{ t('create_button', { ns: 'favourites' }) }</button>
				{serverError && <p className="error-server">{serverError}</p>}
			</div>
		</form>
	</>);
}

export default CreatePanier;