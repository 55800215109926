import { createContext, useReducer, useEffect, useLayoutEffect, useState } from 'react';

import api, { setupInterceptors } from '../api';
import SessionExpiryModal from '../components/SessionExpiryModal';
import RateLimitModal from '../components/RateLimitModal';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN':
			return { user: action.payload }
		case 'LOGOUT':
			return { user: null }
		default:
			return state
	}
}

// Function to validate user data
const isValidUser = (user) => {
	if (!user) return false;
	return (
		typeof user.user_id === 'number' && user.user_id > 0 &&
		typeof user.name === 'string' &&
		typeof user.role === 'number'
	);
};

// App wrapper to provide the context
export const AuthContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		authReducer,
		{ user: null }
	);

	const [showExpiryModal, setShowExpiryModal] = useState(false);
	const [showRateLimitModal, setShowRateLimitModal] = useState(false);
    const [retryAfter, setRetryAfter] = useState(null);

	// Setup Axios interceptors once when the component mounts
	useEffect(() => {
		setupInterceptors(dispatch, setShowRateLimitModal, setRetryAfter);
	}, [dispatch]);

	useLayoutEffect(() => {
		const checkAuth = async (user) => {
			try {
				const response = await api.post('/users/checkAuth');
				if (response.status === 200) {
					// Handle successful authentification = update the auth context
					dispatch({
						type: 'LOGIN',
						payload: user
					});
				}
			} catch (error) {
				if (error.response && error.response.status === 401) {
					console.log("Authentication failed: User data removed from localStorage");
				} else {
					console.log("Error while checking prior authentification");
				}
			}
		}

		const user = JSON.parse(localStorage.getItem("user"));

		if (isValidUser(user)) {
			checkAuth(user);
		}
	}, []);

	const handleConfirm = async () => {
		setShowExpiryModal(false);

		try {
			const response = await api.post('/users/refreshSession');

			// Refresh the user info in case the state/localStorage was tampered with
			const updatedUser = response.data.user_state;

			// Save the user info in localStorage
			localStorage.setItem("user", JSON.stringify(updatedUser));

			// Update the auth context
			dispatch({
				type: 'LOGIN',
				payload: updatedUser
			});
		} catch (error) {
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
		}
	};

	const handleCancel = async () => {
		setShowExpiryModal(false);

		try {
			await api.post('/users/logout');
		} catch (error) {
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
		}

		// Remove user from localStorage
		localStorage.removeItem("user");

		// Update the auth context = dispatch logout action
		dispatch({ type: 'LOGOUT' });
	};

	// Handle session expiry
	useEffect(() => {
		let interval;

		const handleSessionExpiry = async (user) => {
			if (!user || !user.session_expires_at) return;

			const currentTime = Date.now();
			const timeLeft = user.session_expires_at - currentTime;
			const warningTime = 5 * 60 * 1000; // 5 minutes

			if (timeLeft <= warningTime) {
				setShowExpiryModal(true);
			}
		};

		const handleExpiryAndSetInterval = async () => {
			await handleSessionExpiry(state.user);
			interval = setInterval(async () => await handleSessionExpiry(state.user), 60000); // Check every minute
		};

		if (state.user) {
			handleExpiryAndSetInterval();
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [state.user]);
	

	return (
		<AuthContext.Provider value={{...state, dispatch}}>
			{children}
			{showExpiryModal && (
				<SessionExpiryModal
					onConfirm={handleConfirm}
					onCancel={handleCancel}
				/>
			)}
			{showRateLimitModal && (
                <RateLimitModal retryAfter={retryAfter} onClose={() => setShowRateLimitModal(false)} />
            )}
		</AuthContext.Provider>
	)
}