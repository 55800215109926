import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { RouteContextProvider, useRouter } from './contexts/RouteContext';
import { LanguageContextProvider } from './contexts/LanguageContext';
import { ThesaurusContextProvider } from './contexts/ThesaurusContext';
import { AuthContextProvider } from './contexts/AuthContext';

import reportWebVitals from './reportWebVitals';
import './i18n';

// Component that consumes the router context
const RouterConsumer = () => {
	const router = useRouter();
	return <RouterProvider router={router} />;
};

const App = () => (
	<RouteContextProvider>
		<LanguageContextProvider>
			<AuthContextProvider>
				<ThesaurusContextProvider>
					<RouterConsumer />
				</ThesaurusContextProvider>
			</AuthContextProvider>
		</LanguageContextProvider>
	</RouteContextProvider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();