import { useState, useRef, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import '../stylesheets/custom_dropdown.css';

const SelectDropdown = ({ value, options = [], onChange, placeholder, className, setSousCategorie = null }) => {
    const { language } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => setIsOpen((prev) => !prev);

    const handleSelect = (selectedId) => {
        // Reset sousCategorie if categorie changes
        if (setSousCategorie) {
            setSousCategorie('');
        }

        onChange(selectedId);
        setIsOpen(false);  // Close the dropdown after selection
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);  // Close dropdown if clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Filter out the selected value from the list of options
    const filteredOptions = options.filter(option => option.id !== value);

    return (
        <div
            className="custom-dropdown custom-select"
            ref={dropdownRef}
            tabIndex={0}
        >
            <div
                className={`custom-dropdown-header ${className}`}
                onClick={handleToggle}  // Toggle dropdown on click
            >
                {value ?
                    options.find(option => option.id === value)[language === 'fr' ? 'nom_fr' : 'nom_en']
                    : placeholder}

                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
            </div>
            {isOpen && (
                <ul className="custom-dropdown-list">
                    {/* Option to clear the selection and reset to placeholder */}
                    <li
                        key="clear"
                        onClick={() => handleSelect('')}  // Clear selection
                        className="custom-dropdown-item"
                    >
                        {placeholder}
                    </li>
                    {filteredOptions.map((option) => (
                        <li
                            key={option.id}
                            onClick={() => handleSelect(option.id)}
                            className="custom-dropdown-item"
                        >
                            {language === 'fr' ? option.nom_fr : option.nom_en}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SelectDropdown;