import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SelectDropdown from './SelectDropdown';
import { useThesaurusContext } from '../contexts/ThesaurusContext';

import '../stylesheets/custom_dropdown.css';

const ChampCategorie = forwardRef(({ id, value, onChange, setSousCategorie }, ref) => {
	const { t } = useTranslation(['search']);
	const format = require('../util/champsFormat');
	const { thesaurus } = useThesaurusContext();

	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = format.validateIdFormat(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleFocus = (e) => {
		setError(false);
	}

    return (<div className="form-group">
		<label htmlFor={id}>{t('champs.categorie', { ns: 'common' })}</label>
        <SelectDropdown
			id={id}
			options={thesaurus.categories}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			placeholder={t('champs.select', { ns: 'common' })}
			className={error ? "error" : ""}
			setSousCategorie={setSousCategorie}
		/>
    </div>);
});

export default ChampCategorie;