import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MetaTags from './MetaTags';
import api from '../api'
import FavouriteBar from './FavouriteBar';
import LocalizedLink from './LocalizedLink';
import useLocalizedPath from '../hooks/useLocalizedPath';
import { useAuthContext } from '../hooks/useAuthContext';
import { useLanguage } from '../contexts/LanguageContext';
import { useThesaurusContext } from '../contexts/ThesaurusContext';

import '../stylesheets/notice.css';
import '../stylesheets/media.css';
import PanierBar from './PanierBar';

function Decennie({ decennie, language = 'en' }) {
	const searchLink = `/search?decennie=${decennie}`;
	const label = language === "fr" ? `Années ${decennie}` : `The ${decennie}s`;

	return (
		<LocalizedLink to={searchLink} className="tag">{label}</LocalizedLink>
	);
}

function SousCategorie({ tag, language = 'en' }) {
	const searchLink = `/search?categorie=${tag.parent}&sous_categorie=${tag.id}`;
	const label = tag[`nom_${language}`];

	return (
		<LocalizedLink to={searchLink} className="tag">{label}</LocalizedLink>
	);
}

function Tag({ tag, champ, bilingue = false, language = 'en' }) {
	const searchLink = `/search?${champ}=${tag.id}`;
	const label = bilingue ? tag[`nom_${language}`] : tag.nom;

	return (
		<LocalizedLink to={searchLink} className="tag">{label}</LocalizedLink>
	);
}

function MediaPage() {
	const { t } = useTranslation(['media']);
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const { language } = useLanguage();
	const { thesaurus } = useThesaurusContext();
	const format = require('../util/champsFormat');
	const redirect_link = useLocalizedPath("/login");

	// protect page from visitors
    useLayoutEffect(() => {
        if (!user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	const { code_image } = useParams();
	const [notice, setNotice] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [sourceContent, setSourceContent] = useState(null);
	const [notFound, setNotFound] = useState(false);
	const [isFavourite, setIsFavourite] = useState(false);

	// Fetch the image and notice
	useEffect(() => {
		const fetchNotice = async () => {
			try {
				const response = await api.get(`/medias/notice/${code_image}`);
				if (response.status === 200) {
					setNotice(response.data.notice);
					setIsFavourite(response.data.isFavourite);
				}
			} catch (error) {
				setNotice(null); // Clear notice on error
				if (error.response) {
					if (error.response.status === 404) {
						setNotFound(true);
					}
					else {
						console.log("Error while retrieving image notice");
						if (error.response && error.response.data && error.response.data.message) {
							console.error(error.response.data.message);
						}
					}
				}
			}
		};

		const fetchImage = async () => {
			try {
				// Make the request to get the watermarked image as a Blob
				const response = await api.get(`/medias/watermark/${code_image}`, {
					responseType: 'blob', // This tells axios to treat the response as a Blob
				});

				// Handle the response
				if (response.status === 200 || response.status === 304) {
					const url = URL.createObjectURL(response.data);
					setImageUrl(url);
				}
			} catch (error) {
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				} else {
					console.error(`Error fetching watermarked image ${code_image}: `, error);
				}
			}
		};

		if (code_image) {
			fetchNotice();
			fetchImage();
		}
		
		// eslint-disable-next-line
	},[code_image]);

	// Fetch source info
	useEffect(() => {		
		// Ensure Asynchronous Data is Loaded
		if (!notice || !thesaurus || !thesaurus.sources || thesaurus.sources.length === 0) return;

		if (notice.media.source_doc) {
			const source = thesaurus.sources.find(option => option.id === notice.media.source_doc);
			setSourceContent(<LocalizedLink to={"/source/" + source.id}>{source.nom}</LocalizedLink>);
		} else {
			setSourceContent(<p>–</p>);
		}
		// eslint-disable-next-line
	},[thesaurus, notice]);

	// Make Suspense display the Loader if user isn't loaded yet
	if (!user) {
        return null;
    }

	if (notFound) {
		return (<>
			<MetaTags />
			
			<h1>{ t('title', { ns: 'media' }) }</h1>
			<p>{ t('not_found', { ns: 'media' }) }</p>
		</>);
	}

	if (!notice || !imageUrl || !sourceContent) {
		return (<>
			<MetaTags />

			<h1>{ t('title', { ns: 'media' }) }</h1>
			<p>{ t('loading', { ns: 'common' }) }</p>
		</>);
	}
	
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'media' }) }</h1>
		
		<div id="image">
			<img src={imageUrl} alt="Image" />
		</div>

		<div panier-bar>
			<FavouriteBar
				isFavourite={isFavourite}
				setIsFavourite={setIsFavourite}
				code_image={code_image}
				user_id={user.user_id}
			/>

			{user.role >= 2 &&
			<PanierBar
				code_image={code_image}
				user_id={user.user_id}
			/>
			}
		</div>

		<div id="notice-container">
			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.code_image', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					<p>{ notice.media.code_image }</p>
				</div>
			</div>

			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.source', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					{sourceContent}
				</div>
			</div>

			{notice.media.date_parution &&
			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.date_parution', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					{format.processDateParution(notice.media.date_parution, language)}
				</div>
			</div>}

			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.categorie', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					{notice.categories.length > 0 ?
						notice.categories.map((tag, index) => (
							<Tag
								key={index}
								tag={tag}
								champ="categorie"
								bilingue={true}
								language={language}
							/>
						))
					: <p>–</p>}
				</div>
			</div>

			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.sous_categorie', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					{notice.sous_categories.length > 0 ?
						notice.sous_categories.map((tag, index) => (
							<SousCategorie
								key={index}
								tag={tag}
								champ="sous_categorie"
								bilingue={true}
								language={language}
							/>
						))
					: <p>–</p>}
				</div>
			</div>

			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.legende', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					<p>{ notice.media.legende ? notice.media.legende : "–" }</p>
				</div>
			</div>

			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.mots_cles', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					{notice.mots_cles.length > 0 ?
						notice.mots_cles.map((tag, index) => (
							<Tag
								key={index}
								tag={tag}
								champ="mot_cle"
								bilingue={true}
								language={language}
							/>
						))
					: <p>–</p>}
				</div>
			</div>

			{notice.styles && notice.styles.length > 0 &&
			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.style', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">{
					notice.styles.map((tag, index) => (
						<Tag
							key={index}
							tag={tag}
							champ="style"
							bilingue={true}
							language={language}
						/>
					))}
				</div>
			</div>}

			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.decennie', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					{notice.decennies.length > 0 ?
						notice.decennies.map((decennie, index) => (
							<Decennie
								key={index}
								decennie={decennie}
								language={language}
							/>
						))
					: <p>–</p>}
				</div>
			</div>

			{notice.media.annee &&
			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.annee', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					{notice.media.annee ?
					<LocalizedLink to={"/search?annee=" + notice.media.annee}>{notice.media.annee}</LocalizedLink> :
					<p>–</p>}
				</div>
			</div>}

			{notice.couturiers && notice.couturiers.length > 0 &&
			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.couturier', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">{
					notice.couturiers.map((tag, index) => (
						<Tag
							key={index}
							tag={tag}
							champ="couturier"
						/>
					))}
				</div>
			</div>}

			{notice.designers_illustrateurs && notice.designers_illustrateurs.length > 0 &&
			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.designer', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">{
					notice.designers_illustrateurs.map((tag, index) => (
						<Tag
							key={index}
							tag={tag}
							champ="designer"
						/>
					))}
				</div>
			</div>}

			{notice.coloris && notice.coloris.length > 0 &&
			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.coloris', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">{
					notice.coloris.map((tag, index) => (
						<Tag
							key={index}
							tag={tag}
							champ="coloris"
							bilingue={true}
							language={language}
						/>
					))}
				</div>
			</div>}

			{notice.media.remarque &&
			<div className="champ">
				<div className="champ-nom">
					<p>{ t('champs.remarque', { ns: 'common' }) }</p>
				</div>
				<div className="champ-value">
					<p>{notice.media.remarque}</p>
				</div>
			</div>}

		</div>
	</>);
}

MediaPage.namespaces = ['media'];

export default MediaPage;