import { useState, useRef, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import '../stylesheets/custom_dropdown.css';

const SelectDropdownPanier = ({ value, options = [], onChange, placeholder }) => {
    const { language } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => setIsOpen((prev) => !prev);

    const handleSelect = (panier) => {
        onChange(panier);
        setIsOpen(false);  // Close the dropdown after selection
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);  // Close dropdown if clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Filter out the selected value from the list of options
	let filteredOptions = options;
	if (value) {
		filteredOptions = options.filter(option => option.panier_id !== value.panier_id);
	}

    return (
        <div
            className="custom-dropdown custom-select"
            ref={dropdownRef}
            tabIndex={0}
        >
            <div
                className={`custom-dropdown-header`}
                onClick={handleToggle}  // Toggle dropdown on click
            >
                {value ?
                    options.find(option => option.panier_id === value.panier_id)[language === 'fr' ? 'nom' : 'nom_en']
                    : placeholder}

                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
            </div>
            {isOpen && (
                <ul className="custom-dropdown-list">
                    {filteredOptions.length > 0 ?
					filteredOptions.map((option) => (
                        <li
                            key={option.panier_id}
                            onClick={() => handleSelect(option)}
                            className="custom-dropdown-item"
                        >
                            {language === 'fr' ? option.nom : option.nom_en}
                        </li>
                    )) :
					<li className="custom-dropdown-item">
						{language === 'fr' ? "Aucun dossier" : "No folder"}
					</li>}
                </ul>
            )}
        </div>
    );
};

export default SelectDropdownPanier;