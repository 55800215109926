import React, { Suspense, useLayoutEffect, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { useLanguage } from '../contexts/LanguageContext';
import Loader from './Loader';
import Header from './Header';
import Footer from './Footer';
import NamespaceLoader from './NamespaceLoader';

import '../stylesheets/main.css';

function Root() {
	const { language } = useLanguage();
	const location = useLocation();
	const navigate = useNavigate();
	
	// Update path based on detected language if necessary
	useEffect(() => {
		const currentPath = location.pathname;
		const currentSearch = location.search; // Preserve query parameters
		const currentHash = location.hash;     // Preserve hash fragments

		// Add language prefix if needed
		if (language === 'fr' && !currentPath.startsWith('/fr')) {
			const newPath = `/fr${currentPath}${currentSearch}${currentHash}`;
			navigate(newPath, { replace: true });
		}
		// Remove language prefix if needed
		else if (language !== 'fr' && currentPath.startsWith('/fr')) {
			const newPath = currentPath.replace(/^\/fr/, '') + currentSearch + currentHash;
			navigate(newPath, { replace: true });
		}
		// eslint-disable-next-line
	  }, []);
	
	// scroll to top of page after a page transition.
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [location.pathname, location.search, navigate]);

	return (<>
		<Suspense fallback={<Loader />}>
			<HelmetProvider>
				<Header />
				<main className="page-main">
                    <NamespaceLoader element={Outlet} />
                </main>
				<Footer />
			</HelmetProvider>
		</Suspense>
	</>);
}

export default Root;